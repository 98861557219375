import React, { useEffect, useCallback, useState } from 'react';
import MembersLayout from '../../../components/Members/Layout';
import Container from '../../../components/Container';
import { Flex, Box } from 'rebass';
import Card from '../../../components/Card';
import Input from '../../../components/Input';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import { FiExternalLink } from 'react-icons/fi';
import ContentLoader from 'react-content-loader';
import usePrismic from '../../../utils/hooks/members/usePrismic';
import { getLibraryPage } from '../../../components/Members/queries';
import SEO from '../../../components/seo';

const MembersLibraryPage = () => {
  const [fetch, { data, loading }] = usePrismic({ query: getLibraryPage });
  useEffect(() => {
    fetch();
  }, []);
  const [didCopyUsername, onCopyUsername] = useState(false);
  const [didCopyPassword, onCopyPassword] = useState(false);
  const node = data?.allLibrary_pages?.edges[0]?.node;
  const copyToClipboard = useCallback((value, callback) => {
    if (
      typeof document !== 'undefined' &&
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');
      textarea.textContent = value;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        return false;
      } finally {
        document.body.removeChild(textarea);
        callback(true);
        setTimeout(() => {
          callback(false);
        }, 2000);
      }
    }
  }, []);
  return (
    <MembersLayout title="Library" goBackLink="/members">
      <SEO title="Library" />
      <Container py={[3, 4]} maxWidth={480}>
        {!loading ? (
          <>
            <Text mb={3}>{node?.instructions[0].text}</Text>
            <Flex m={-3} flexDirection="column">
              <Box p={3}>
                <Card p={3}>
                  <Text variant="tertiary" mb={1} fontSize={3}>
                    {node?.step_one_heading[0].text}
                  </Text>
                  <Text variant="secondary" mb={3}>
                    1. {node?.step_one_instructions[0].text}
                  </Text>
                  <a
                    href={node?.step_one_button_link?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button width={1} variant="secondary">
                      <Flex alignItems="center" justifyContent="space-between">
                        {node?.step_one_button_label[0].text}
                        <FiExternalLink />
                      </Flex>
                    </Button>
                  </a>
                </Card>
              </Box>
              <Box p={3}>
                <Card p={3}>
                  <Text variant="tertiary" mb={1} fontSize={3}>
                    {node?.step_two_heading[0].text}
                  </Text>
                  <Text variant="secondary" mb={3}>
                    1. {node?.step_two_instructions[0].text}
                  </Text>
                  <Input
                    label="Username"
                    mb={3}
                    mr={2}
                    value={node?.step_two_username}
                  />
                  <Button
                    disabled={didCopyUsername}
                    onClick={() =>
                      copyToClipboard(node?.step_two_username, onCopyUsername)
                    }
                  >
                    {didCopyUsername ? 'Copied! ' : 'Copy'}
                  </Button>
                  <Input
                    label="Password"
                    mr={2}
                    value={node?.step_two__password}
                  />
                  <Button
                    disabled={didCopyPassword}
                    onClick={() =>
                      copyToClipboard(node?.step_two__password, onCopyPassword)
                    }
                  >
                    {didCopyPassword ? 'Copied! ' : 'Copy'}
                  </Button>
                </Card>
              </Box>
            </Flex>
          </>
        ) : (
          <>
            <ContentLoader
              speed={2}
              width={320}
              height={24}
              viewBox="0 0 320 24"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="4" ry="4" width="320" height="24" />
            </ContentLoader>
            {[...Array(2).keys()].map((_, idx) => (
              <Card my={3} p={3} key={`loader-${idx}`}>
                <ContentLoader
                  speed={2}
                  width={320}
                  height={96}
                  viewBox="0 0 320 96"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="128" height="24" />
                  <rect x="0" y="36" rx="4" ry="4" width="256" height="24" />
                  <rect x="0" y="72" rx="4" ry="4" width="290" height="24" />
                </ContentLoader>
              </Card>
            ))}
          </>
        )}
      </Container>
    </MembersLayout>
  );
};

MembersLibraryPage.propTypes = {};

export default MembersLibraryPage;
